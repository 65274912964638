import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from 'styled-components'

import BackgroundWrapper from '~/components/BackgroundWrapper'
import Layout from '~/components/Layout'
import Hero from '~/components/Hero'

const Title = styled.h1`
`

const ContentWrapper = styled(BackgroundWrapper)`
  padding: 20px;
  margin: 0 auto;
  max-width: 700px;
`


export default ({ data, location }) => {
  const service = get(data, 'contentfulServizio')
  const siteMetadata = get(data, 'site.siteMetadata')
  let pages = get(data, 'allContentfulPage.edges')
    .map(({ node }) => node)
  const [metadata] = get(data, 'allContentfulMetadata.edges')

  pages.push({ title: 'Contattaci', slug: 'contatti' })

  // Add name for social sharing buttons
  service.name = metadata.node.name

  const title = `${service.title} | ${siteMetadata.title}`

  return (
    <Layout
      location={location}
      title={title}
      url={siteMetadata.url}
      pages={pages}
      metadata={metadata.node}
    >
      <Hero data={service} location={location} />
      <ContentWrapper>
        <Title>{service.title}</Title>
        {documentToReactComponents(service.body.json)}
      </ContentWrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ServiceBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        url
      }
    }
    contentfulServizio(slug: { eq: $slug }) {
      title
      heroImage {
        title
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid
        }
      }
      body {
        json
      }
    }
    allContentfulPage(sort: {fields: position}) {
      edges {
        node {
          title
          slug
          content {
            json
          }
          services {
            title
            slug
            heroImage {
              title
              fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
                ...GatsbyContentfulFluid
              }
            }
            body {
              json
            }
          }
          map {
            url
          }
        }
      }
    }
    allContentfulMetadata {
      edges {
        node {
          name
          body {
            json
          }
          text {
            text
          }
          keywords
          email
          phone
          cell
          fax
          address
          iva
          heroImage: image {
            title
            fluid(
              maxWidth: 1180,
              maxHeight: 480,
              resizingBehavior: PAD
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
